.container {
  position: absolute;
  top: 60px;
  left: 0;
  background: white;
  padding: 20px 20px 25px;
  border-top-right-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 2;
}
