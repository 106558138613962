.container {
  height: 380px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 !important;
}

.chartWrapper {
  height: 100%;
}

.priceChartWrapper {
  height: 65%;
}

.countChartWrapper {
  height: 35%;
}

.tooltip {
  background: #fff;
  padding: 9px 12px;
  border: 1px solid #ccc;
  max-width: 300px;
}

.outlierCheckbox {
  float: right;
}
